/*colors*/
$accent-color: #0b8ccc;
$light-color: #0c8ccd;
$text-color: #3b3a3e;
$stroke-color: #e3e1eb;
$light-gray: #f2f6f7;
$dark-color: #372e2c;

/*fonts*/
$title-font: "Montserrat";
$body-font: "Myriad Pro";
$popings-font: "Poppins";

/*breakpoints*/
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

$grid-breakpoints-down: (
  xs: 575.98px,
  sm: 767.98px,
  md: 991.98px,
  lg: 1199.98px,
  xl: 1399.98px,
  xxl: 0,
);

@mixin center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin transition {
  transition: all 0.5s;
}
