@import "../variables";

font-face {
  font-family: "Montserrat";
  src: url(../webfonts/Montserrat-Medium.ttf);
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(../webfonts/Montserrat-Bold.ttf);
  font-weight: bold;
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: $body-font;
  color: $text-color;
}

p {
  text-align: justify;
}

img,
.lazy-load-image-background {
  max-width: 100%;
}

.loader {
  @include center-flex;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  font-size: 2rem;
  background: #fff;
  z-index: 1000;
  color: $accent-color;
}

#root:not(:empty) + .loader {
  display: none;
}

.lazy-load-image-background {
  width: 100%;
}

.img-container,
.service-content-image {
  margin: 2rem;
  padding: 1rem;
  border: 0.3rem solid $accent-color;
  border-radius: 25px;

  img {
    border-radius: 25px;
  }
}

.content-container {
  img {
    padding: 1rem;
    border: 0.3rem solid $accent-color;
    border-radius: 25px;
    max-width: 100%;
  }

  figure {
    &.image {
      width: 450px !important;
      max-width: 100%;
      margin-bottom: 2rem;

      &.image-style-align-right {
        float: right;
        padding-left: 2rem;
      }

      &.image-style-align-left {
        float: left;
        padding-right: 2rem;
      }
    }
  }
}

.service-content-text {
  margin: 1rem 2rem;

  h3 {
    color: $light-color;
    margin-bottom: 1rem;
  }
}
